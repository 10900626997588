<template>
    <div style="width: 80%; margin: auto; padding: 10px">
        <div>&nbsp;</div>
        <div style="text-align: center;">
            <h1>PRIVACY POLICY</h1>    
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left; font-size: 24px">
            <p>At Event! Camera, we protect the privacy of those who use our technology and website. The following Privacy Statement provides details about how your personal and business information is collected and used.</p>
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left;">
            <h3>INFORMATION COLLECTED</h3>    
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left; font-size: 24px">
            <p>Event! Camera is the sole owner of information provided and/or collected on this site. We will not sell, share, or rent this information to others in any way different from what is disclosed in this statement.</p>
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left;">
            <h3>SHARING AND USAGE</h3>    
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left; font-size: 24px">
            <p>We will never share, sell, or rent your personal or business information with third parties for their promotional use. We may disclose or report information in limited circumstances where we believe in good faith that disclosure is required under the law.</p>
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left;">
            <h3>SECURITY</h3>    
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left; font-size: 24px">
            <p>We take every precaution to protect the confidentiality and security of your information by using industry-recognized security safeguards coupled with carefully developed security procedures to protect your information from loss, misuse or unauthorized alteration.</p>
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left;">
            <h3>NOTIFICATION OF CHANGES</h3>    
        </div>
        <div>&nbsp;</div>
        <div style="text-align: left; font-size: 24px">
            <p>If we decide to change our privacy policy, we will post those changes on our Web site. It is our intent to ensure our users are always aware of what information we collect and use, as well as under what circumstances we disclose it.</p>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'PrivacyView'
  }
</script>