<template>
  <div>
    <v-app light>
      <section>
        <v-parallax src="assets/IMG_3457.JPG" height="500" width="100%">
          <div class="d-flex flex-column fill-height justify-center align-center text-white">
            <h1 class="text-h4 font-weight-thin mb-4">
              <img src="assets/eventcamera.png" alt="Vuetify.js" height="200">
            </h1>
            <h2 class="subheading">
              <h1 class="white--text mb-2 display-1 text-center">Event! Camera</h1>
            </h2>
            <h4 class="subheading">
              <div class="subheading mb-4 text-center">Share Events with Photos</div>
            </h4>
            <v-btn
                class="mt-12"
                color="blue lighten-2"
                dark
                large
                href="/" >
                Coming Soon!
            </v-btn>
          </div>
        </v-parallax>
      </section>

      <section>
        <v-row xs12 sm4 class="my-4">
          <v-col xs12>
          <div class="text-center">
            <h2 class="headline">Easily share all of your events with photos with friends and family.</h2>
          </div>
          </v-col>
        </v-row>
        <v-row xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-col xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2">mdi-palette</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Get an Event Code</div>
                  </v-card-title>
                  <v-card-text>
                    Event! Camera works by obtaining a code that can be shared with anyone using a code or QR code that will be attending your event.  
                    Anyone subscribed to this event via the code, can see the photos for the event and add their own photos through the app.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2">mdi-flash</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Real Time Sharing</div>
                  </v-card-title>
                  <v-card-text>
                    The instant you take a photo and it shows up on the main feed, all other users subsribed to the event will get that photo as well.
                    Sharing of photos has never been easier.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2">mdi-wrench</v-icon>
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Display the Event at the Event</div>
                  </v-card-title>
                  <v-card-text>
                    The event can be set up to display your photos on a screen during your event.  This allows people to take photos, share them in the app,
                    and see them as a collage while at the event.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-layout>
          </v-container>
        </v-row>
      </section>

      <section>
        <v-parallax src="assets/IMG_3451.JPG" height="380">
          <div class="d-flex flex-column fill-height justify-center align-center text-white">
            <h2>Sharing important events with photos has never been easier.</h2>
            <em>Start sharing your events today</em>
            <v-btn
              class="mt-12"
              color="blue lighten-2"
              dark
              large
              href="/" >
              Coming Soon!
            </v-btn>
          </div>
        </v-parallax>
      </section>

      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-12">
            <v-col xs12 sm4>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">About</div>
                </v-card-title>
                <v-card-text>
                  Event! Camera is a side project that is evolving into a real product.  Originally written to share pictures real time on a big screen 
                  at a halloween party.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col xs12 sm4 offset-sm1>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Other Information</div>
                </v-card-title>
                <v-card-text>
                  Here are some links to our privacy policy and other stuff.
                </v-card-text>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-list-item-title>Privacy Policy:</v-list-item-title>
                    </v-list-item-action>
                    <v-list-item-content>
                      <RouterLink to="/privacy">Click Here</RouterLink>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-layout>
        </v-container>
      </section>

      <v-footer color="blue darken-2">
        <v-layout row wrap align-center>
          <v-col xs12>
          
          </v-col>
        </v-layout>
      </v-footer>
    </v-app>
  </div>
</template>

<css scoped>
  div {
    width: 100%;
  }
</css>

<script>
  export default {
    name: 'DefaultView',
    components: {}
}
</script>