import { createRouter, createWebHistory } from 'vue-router'
import PrivacyView from '@/components/PrivacyView'
import DefaultView from '@/components/DefaultView'

export const router = createRouter({
  base: "/",
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: DefaultView,
      props: {title: "Welcome to Event! Camera"},
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyView
    },
    {
      path: '/:id?',
      component: DefaultView,
      props: {title: "Welcome to Event! Camera"},
      meta: {
        requiresAuth: false
      }
    }
  ]
})