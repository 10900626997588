import '@mdi/font/css/materialdesignicons.css'
import{ createApp } from 'vue'
import { router } from './router'
import { store } from './store'
import Vuex from 'vuex'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import App from './App.vue'

const app = createApp(App);

app.use(router);
app.use(store);

app.config.productionTip = false;

const vuetify = createVuetify({
  components: {
      ...components,
      ...labs
  },
  directives,
  icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
          mdi,
      }
  }
});

app.use(vuetify);
app.use(Vuex);

app.mount('#app');